import React from "react"
import { PageProps, graphql, Link } from "gatsby"
import { Card, Seo } from "@components"
import { MainLayout } from "@components/layouts"
import { Query } from "types/gatsby-graphql"
import { getExcerptText } from "@utils/blogUtils"

interface IProps extends PageProps {
  data: Query
}

const IndexPage: React.VFC<IProps> = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <MainLayout>
      <Seo title="Landometer Blog" />
      <h1 className="title has-text-centered">Recently Post</h1>
      <div className="columns is-multiline blog-cards">
        {edges
          .filter(edges => !!edges?.node?.frontmatter?.date)
          .map(edge => (
            <div className="column is-4" key={edge.node.id}>
              <Card
                fields={edge.node.fields!!}
                frontmatter={edge.node.frontmatter!!}
                excerpt={getExcerptText(edge.node.html || "")}
              />
            </div>
          ))}
      </div>
      <div className="has-text-centered">
        <Link to={"/blog"}>ดูบทความทั้งหมด</Link>
      </div>
    </MainLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
